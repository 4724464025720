import {
	call,
	delay,
	put,
	select,
	takeEvery,
	takeLatest,
} from 'redux-saga/effects';
import {
	selectPatientDetails,
	selectPatientPrescriptionMessage,
	selectPatientSummery,
	selectReAssignedPractitionerId,
	selectSelectedPatient,
} from './selector';
import { actions } from './slice';
import { API_URL } from 'utils/constants';
import { request } from 'utils/request';
import { selectSelectedPractitioner } from 'app/containers/Practitioner/redux/selector';
import { emailValidator } from 'utils/helpers';
import { t } from 'i18next';
import queryString from 'query-string';
import { actions as authActions } from '../../Auth/slice';
import { pageSize } from 'app/containers/Practitioner';

export function* searchPatientsRequest(action) {
	const searchTerm = action.payload;
	yield delay(1000);
	let selected_practitioner = yield select(selectSelectedPractitioner);
	const options = {
		method: 'GET',
	};
	const queries = queryString.stringify({
		searchTerm: searchTerm,
		pageSize: pageSize,
		pageNo: 1,
		status: 0,
		practitioner: selected_practitioner,
	});
	try {
		const response = yield call(
			request,
			`${API_URL}/patient?${queries}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'BASIC_FOOD.FOOD_CATEGORIES_FETCH.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(actions.updatePatientsList(response.data[0].results));
		if (response.data[0].results.length > 0) {
			yield put(actions.updatePageNo(response.data[0].meta.page));
			yield put(actions.updateTotalCount(response.data[0].meta.total));
		} else {
			yield put(actions.updatePageNo(0));
			yield put(actions.updateTotalCount(0));
		}
	} catch (e) {
		yield put(actions.setError('something went wrong'));
		console.log(e);
	}
}

export function* searchIngredientsRequest(action) {
	const searchTerm = action.payload;
	const options = {
		method: 'GET',
	};
	const queries = queryString.stringify({
		searchTerm: searchTerm,
		pageNo: 1,
	});
	yield put(actions.setLoadingIngredients(true));
	try {
		const response = yield call(
			request,
			`${API_URL}/ingredients?${queries}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.INGREDIENTS_LABELS_FETCH.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(actions.updateIngredientsList(response.data));
		yield put(actions.setLoadingIngredients(false));
	} catch (e) {
		yield put(actions.setError('something went wrong'));
		yield put(actions.setLoadingIngredients(false));
		console.log(e);
	}
}

export function* searchPatientArchivedRequest(action) {
	const searchTerm = action.payload;
	yield delay(1000);
	let selected_practitioner = yield select(selectSelectedPractitioner);
	const options = {
		method: 'GET',
		// body: JSON.stringify({ searchTerm: searchTerm, pageSize: 100, pageNo: 1 }),
	};
	const queries = queryString.stringify({
		searchTerm: searchTerm,
		pageSize: pageSize,
		pageNo: 1,
		status: 1,
		practitioner: selected_practitioner,
	});
	try {
		const response = yield call(
			request,
			`${API_URL}/patient?${queries}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'BASIC_FOOD.FOOD_CATEGORIES_FETCH.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(actions.updateArchivedpatientsList(response.data[0].results));
		if (response.data[0].results.length > 0) {
			yield put(actions.updatePageNoArchived(response.data[0].meta.page));
			yield put(actions.updateTotalCountArchived(response.data[0].meta.total));
		} else {
			yield put(actions.updatePageNoArchived(0));
			yield put(actions.updateTotalCountArchived(0));
		}
	} catch (e) {
		yield put(actions.setError('something went wrong'));
		console.log(e);
	}
}
export function* addPatientRequest(action) {
	let selected_practitioner = yield select(selectSelectedPractitioner);
	const {
		fullName,
		email,
		_id,
		mobileNumber,
		countryCode = '+1',
	} = yield select(selectSelectedPatient);
	if (fullName.length === 0) {
		yield put(actions.setError('Please enter full name'));
		return;
	}

	if (!email) {
		yield put(actions.setError('Please enter email'));
		return;
	}
	if (mobileNumber.length === 0) {
		yield put(actions.setError('Please enter mobile number'));
		return;
	}
	if (countryCode.length === 0) {
		yield put(actions.setError('Please enter country code'));
		return;
	}
	if (!emailValidator(email)) {
		yield put(actions.setError('Please enter correct email'));
		return;
	}
	//  TODO: if we need this in future?
	// if (id.length === 0) {
	// 	yield put(actions.setError('Please enter Identity number'));
	// 	return;
	// }
	const options = {
		method: 'POST',
		body: JSON.stringify({
			fullName: fullName,
			email,
			practitioner_id: selected_practitioner,
			mobileNumber: mobileNumber,
			countryCode: countryCode,
		}),
	};
	try {
		const response = yield call(request, `${API_URL}/patient/add`, options);
		if (
			response &&
			response.messageCode ===
				'MESSAGE.PATIENT_ADDED_SUCCESS.SMS_STORE_LINK_FAILED'
		) {
			yield put(actions.searchPatients(''));
			yield call(action?.payload?.callback);
			yield put(actions.addSuccess());
			yield put(actions.updateLoader(false));
			yield put(actions.toggleAddPatientPopup(false));
			yield put(actions.setShowAlert(true));
		} else if (
			response &&
			response.messageCode !== 'MESSAGE.PATIENT_ADDED_SUCCESS'
		) {
			yield put(actions.setError(t(`${response.message}`)));
		} else {
			yield put(actions.searchPatients(''));
			yield call(action?.payload?.callback);
			yield put(actions.addSuccess());
			yield put(actions.updateLoader(false));
			yield put(actions.toggleAddPatientPopup(false));
		}
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors.length > 0) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			if (erros.messageCode === 'ERRORS.AUTH.SEND_OTP_FAILED') {
				yield put(actions.setError(erros.message));
			}
			erros.message
				? yield put(actions.setError(erros.message))
				: yield put(actions.setError('Something went wrong'));
		}
		console.log(e);
		yield put(actions.updateLoader(false));
	}
}
export function* getPatientRequest(action) {
	const patientId = action.payload;
	yield put(authActions.setLoading(true));
	const options = {
		method: 'GET',
		// body: JSON.stringify({ searchTerm: searchTerm, pageSize: 100, pageNo: 1 }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/patient/${patientId}`,
			options,
		);

		if (
			response &&
			response.messageCode !== 'MESSAGE.CONTACT_PERSON_GET.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(
			actions.updateFormValue({
				key: 'fullName',
				value: response.data.fullName,
			}),
		);
		yield put(
			actions.updateFormValue({ key: 'email', value: response.data.email }),
		);
		yield put(
			actions.updateFormValue({
				key: 'identity_number',
				value: response.data.identity_number,
			}),
		);
		yield put(
			actions.updateFormValue({ key: '_id', value: response.data._id }),
		);
		// yield put(
		//   actions.updateNutrientsVitaminsIncludeExlude(
		//     response.data.practitionerPreferences,
		//   ),
		// );
		yield put(actions.updatePatient(response.data));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* editPatientRequest(action) {
	let selected_practitioner = yield select(selectSelectedPractitioner);
	const { email, _id, mobileNumber, countryCode, fullName } = yield select(
		selectSelectedPatient,
	);

	if (fullName.length === 0) {
		yield put(actions.setError('Please enter full name'));
		return;
	}
	if (!email) {
		yield put(actions.setError('Please enter email'));
		return;
	}
	if (!emailValidator(email)) {
		yield put(actions.setError('Please enter correct email'));
		return;
	}
	//  TODO: if we need this in future?
	// if (id.length === 0) {
	// 	yield put(actions.setError('Please enter Identity number'));
	// 	return;
	// }
	const options = {
		method: 'PUT',
		body: JSON.stringify({
			fullName,
			_id: _id,
			email,
			practitioner_id: selected_practitioner,
			countryCode,
			mobileNumber,
		}),
	};
	try {
		const response = yield call(request, `${API_URL}/patient`, options);
		if (
			response &&
			response.messageCode ===
				'MESSAGE.PATIENT_ADDED_SUCCESS.SMS_STORE_LINK_FAILED'
		) {
			yield put(actions.searchPatients(''));
			yield call(action?.payload?.callback);
			yield put(actions.addSuccess());
			yield put(actions.updateLoader(false));
			yield put(actions.toggleAddPatientPopup(false));
			yield put(actions.setShowAlert(true));
		} else if (
			response &&
			response.messageCode !== 'MESSAGE.CLINIC_ADD.SUCCESS'
		) {
			yield put(actions.setError(t(`${response.message}`)));
			return;
		} else {
			yield call(action?.payload?.callback);
			yield put(actions.addSuccess());
			yield put(actions.toggleAddPatientPopup(false));
		}
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* reAssignePatientRequest(action) {
	yield delay(500);
	let selected_practitioner = yield select(selectReAssignedPractitionerId);
	const { _id } = yield select(selectSelectedPatient);

	if (_id.length === 0) {
		yield put(actions.setError('Please enter Identity number'));
		return;
	}
	const options = {
		method: 'PUT',
		body: JSON.stringify({ _id: _id, practitioner_id: selected_practitioner }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/patient/reassign`,
			options,
		);
		if (response && response.messageCode !== 'MESSAGE.REASSIGNED.SUCCESS') {
			yield put(actions.setError(t(`${response.message}`)));
			return;
		} else {
			yield call(action?.payload?.callback);
			yield put(actions.addSuccess());
			yield put(actions.setReAssignPractitionerId({ id: '' }));
		}
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* UpdatePractitionerStatusRequest(action) {
	yield delay(500);
	const { _id } = yield select(selectSelectedPatient);
	const options = {
		method: 'PUT',
		body: JSON.stringify({ _id: _id, status: action.payload.status }),
	};
	try {
		const response = yield call(request, `${API_URL}/patient/status`, options);
		if (response && response.messageCode !== 'MESSAGE.CLINIC_ADD.SUCCESS') {
			yield put(actions.setError(t(`${response.message}`)));
			return;
		}
		yield put(actions.addSuccess());
		yield call(action?.payload?.callback);
		yield put(actions.searchPatients(''));
		yield put(actions.searchPatientsArchived(''));
		yield put(
			actions.toggleSnackbar({
				message: t(`${response.messageCode}`),
				open: true,
				translate: true,
				variant: 'success',
			}),
		);
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError(erros?.message ?? 'Some thing went wrong'));
		}
		console.log(e);
	}
}
export function* DeletePatient(action) {
	yield delay(500);
	const selectedPatientInfo = yield select(selectSelectedPatient);
	const id = selectedPatientInfo._id;
	const options = {
		method: 'DELETE',
	};
	try {
		const response = yield call(request, `${API_URL}/patient/${id}`, options);
		if (
			response &&
			response.messageCode !== 'MESSAGE.CONTACT_PERSON_DELETE.SUCCESS'
		) {
			yield put(actions.setError(t(`${response.message}`)));
			return;
		}
		yield call(action?.payload?.callback);
		yield put(actions.updateLoader(false));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		yield put(actions.updateLoader(false));
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}

export function* searchNutrientsAndVitaminsSubDetails(action) {
	// yield delay(500);
	const options = {
		method: 'GET',
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/patient/nutrients/${action.payload}`,
			options,
		);
		if (response && response.length === 0) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(actions.getNutrientsAndVitaminsSubDetails(response));
	} catch (e) {
		yield put(actions.setError('something went wrong'));
		console.log(e);
	}
	yield put(authActions.setLoading(false));
}

export function* fetchNutrientAndVitaminDetails(action) {
	// yield delay(1000);
	const options = {
		method: 'GET',
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/patient/nutrients`,
			options,
		);
		if (response && response.length === 0) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(actions.assignNutrientAndVitaminDetails(response?.nutrientsList));
		yield put(actions.assignRestrictionsDetails(response?.restrictions));
	} catch (e) {
		yield put(actions.setError('something went wrong'));
		console.log(e);
	}
}

export function* UpdatePatientDetails(action) {
	// yield delay(500);
	const data = yield select(selectPatientDetails);
	const options = {
		method: 'PUT',
		body: JSON.stringify(data),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/patient/practitioner-patient-details`,
			options,
		);
		if (
			response &&
			response.messageCode !==
				'MESSAGE.USER_PROFILE.PATIENT_DETAILS_UPDATE_SUCCESS'
		) {
			yield put(actions.setError(t(`${response.message}`)));
			return;
		}
		put(actions.setSnackBarStatus(true));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError(erros?.message ?? 'Some thing went wrong'));
		}
	}
}

export function* checkPaymentStatus(action) {
	yield delay(500);

	const options = {
		method: 'POST',
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/practitioner/check-payment-status/new-client`,
			options,
		);
		if (response && response.status) {
			yield call(action?.payload?.onSuccessStatusCallback);
		} else if (
			response &&
			response.messageCode ===
				'MESSAGE.CHECK_PAYMENT_STATUS.ADD_CLIENT.SUCCESS' &&
			response.status === false
		) {
			const userId = response?.data?.userId;
			const link = response?.data?.promoCodeData?.payment_link;
			if (!userId) {
				yield put(
					actions.setAddPatientError(
						`We don't have the information we need to move you to the payment stage`,
					),
				);
				yield put(authActions.setLoading(false));
				return;
			}
			if (!link) {
				yield put(
					actions.setAddPatientError(
						'There was a problem with the link to the payment step',
					),
				);
				yield put(authActions.setLoading(false));
				return;
			}
			if (
				response.data.promoCodeData.payment_method === 'Paypal' &&
				action?.payload?.openPayment(link)
			) {
				yield call(action?.payload?.openPayment(link));
			} else if (
				response.data.promoCodeData.payment_method === 'Stripe' &&
				action?.payload?.openPayment(`${link}?client_reference_id=${userId}`)
			) {
				yield call(
					action?.payload?.openPayment(`${link}?client_reference_id=${userId}`),
				);
			}
			yield put(authActions.setLoading(false));
			return;
		} else if (
			response &&
			response.messageCode !== 'MESSAGE.CHECK_PAYMENT_STATUS.ADD_CLIENT.SUCCESS'
		) {
			yield put(
				actions.setAddPatientError(
					`There was a problem verifying your payment ${t(response.message)}`,
				),
			);
		}
		yield put(authActions.setLoading(false));
		return;
	} catch (e: any) {
		console.log('ERROR:', e);
		yield put(
			actions.setAddPatientError(e?.message ?? 'Some thing went wrong'),
		);
	}
}

export function* SendPatientPrescriptionMessage(action) {
	yield delay(500);
	const data: any = yield select(selectPatientPrescriptionMessage);
	const _data = {
		...data,
		updateProfile: !action?.payload?.showAlert,
	};
	const options = {
		method: 'POST',
		body: JSON.stringify(_data),
	};
	try {
		const response = yield call(request, `${API_URL}/messages/`, options);
		if (
			response &&
			response.messageCode == 'MESSAGES.SENT_SUCCESS' &&
			action.payload?.showAlert
		) {
			yield put(actions.sendMessage(t(`${response.message}`)));
		} else if (response && response.messageCode !== 'MESSAGES.SENT_SUCCESS') {
			yield put(actions.setError(t(`${response.message}`)));
			return;
		}
		put(actions.setPrescriptionSentStatus(true));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError(erros?.message ?? 'Some thing went wrong'));
		}
	}
}

export function* uploadCsvFile(action) {
	yield delay(500);
	const { userId, data } = action.payload;
	const options = {
		method: 'POST',
		body: JSON.stringify({ data: data, userId: userId }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/patient/upload-csv`,
			options,
		);
		if (response && response.messageCode === 'MESSAGE.UPLOAD_CSV.SUCCESS') {
			yield put(actions.updatePatient(response.data));
		} else if (response && response.messageCode !== 'MESSAGES.SENT_SUCCESS') {
			// TODO: Handle failed
		}
		yield put(actions.endUploadCsvFile());
		return;
	} catch (e: any) {}
}

export function* getCountryCodeByIp() {
	try {
		const response = yield call(
			request,
			'https://api.ipdata.co?api-key=704b751a1f74c8700ae4764b7659ce1eb4ea9f6a305bb88456951565',
		);
		if (response.calling_code) {
			yield put(
				actions.setCountryCode({
					code: response.country_code,
					countryCode: `+${response.calling_code}`,
					flag: response.emoji_flag,
					name: response.country_name,
				}),
			);
		}
	} catch (e: any) {
		yield put(
			actions.setCountryCode({
				countryCode: '+1',
				flag: '🇺🇸',
				code: 'US',
				name: 'United States',
			}),
		);
	}
}

export function* PatientRepoSaga() {
	yield takeLatest(
		actions.searchPatientsArchived.type,
		searchPatientArchivedRequest,
	);
	yield takeLatest(actions.searchPatients.type, searchPatientsRequest);
	yield takeEvery(
		actions.searchNutrientsAndVitaminsSubDetails.type,
		searchNutrientsAndVitaminsSubDetails,
	);
	yield takeEvery(
		actions.fetchNutrientAndVitaminDetails.type,
		fetchNutrientAndVitaminDetails,
	);
	yield takeLatest(actions.changesearchPatient.type, searchPatientsRequest);
	yield takeLatest(
		actions.changesearchArchivedPatient.type,
		searchPatientArchivedRequest,
	);
	yield takeLatest(actions.searchIngredients.type, searchIngredientsRequest);
	yield takeLatest(actions.addPatient.type, addPatientRequest);
	yield takeLatest(actions.getPatient.type, getPatientRequest);
	yield takeLatest(actions.editPatient.type, editPatientRequest);
	yield takeLatest(actions.reassignPractitioner.type, reAssignePatientRequest);
	yield takeLatest(actions.getCountryCodeByIp.type, getCountryCodeByIp);
	yield takeLatest(actions.uploadCsvFile.type, uploadCsvFile);
	yield takeLatest(
		actions.updatePatientStatus.type,
		UpdatePractitionerStatusRequest,
	);
	yield takeLatest(actions.deleteSelectedPatient.type, DeletePatient);
	yield takeLatest(actions.putPatientDetails.type, UpdatePatientDetails);
	yield takeLatest(actions.checkPaymentStatus.type, checkPaymentStatus);
	yield takeLatest(
		actions.sendMessageToPatient.type,
		SendPatientPrescriptionMessage,
	);
}
