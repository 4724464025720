import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	InputLabel,
	Snackbar,
	Stack,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import CountryCodeInput from 'app/components/CountryCodeInput';
import CustomModal from 'app/components/CustomModal';
import { SearchAutoComplete } from 'app/components/SearchAutoComplete';
import SideBar from 'app/components/Sidebar';
import { TableSearch } from 'app/components/TableSearch';
import UserRightBar from 'app/components/UserRightBar';
import {
	selectPractitionerPayment,
	selectPractitionerRole,
} from 'app/containers/Auth/selectors';
import { ClinicRepoSaga } from 'app/containers/Clinics/redux/saga';
import { selectActiveClinicIsVirtual } from 'app/containers/Clinics/redux/selector';
import {
	reducer as reducerClinic,
	sliceKey as sliceKeyClinic,
} from 'app/containers/Clinics/redux/slice';
import PaypalProvider from 'app/containers/Paypal/PaypalProvider';
import { PractitionerRepoSaga } from 'app/containers/Practitioner/redux/saga';
import {
	selectPractitionersList,
	selectSelectedPractitioner,
	selectSelectedPractitionerName,
} from 'app/containers/Practitioner/redux/selector';
import {
	actions as actionsPractitioner,
	reducer as reducerPractitioner,
	sliceKey as sliceKeyPractitioner,
} from 'app/containers/Practitioner/redux/slice';
import { Practitioner } from 'app/containers/Practitioner/types';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { useInjectSaga } from 'utils/redux-injectors';
import {
	IconBag,
	IconBlueArchive,
	IconBluePlus,
	IconBlueReassign,
	IconEdit,
	IconLine,
	IconSearch,
	IconTrashRed,
} from '../../../../assets/images';
import Header from '../../../components/Header';
import Content from '../../../components/content';
import { actions as authActions } from '../../Auth/slice';
import { PatientRepoSaga } from '../redux/saga';
import {
	selectAddPatient,
	selectAddPatientOpen,
	selectArchivePatientOpen,
	selectArchivedSearchTerm,
	selectDeletePatientOpen,
	selectFrom,
	selectIsPrePaymentOpen,
	selectPatientsList,
	selectReAssignedPractitionerId,
	selectReassignPatientOpen,
	selectSearchTerm,
	selectSelectedPatient,
	selectShowAlert,
	selectSnackBarStatus,
	selectUnArchivePatientOpen,
} from '../redux/selector';
import { actions, reducer, sliceKey } from '../redux/slice';
import ArchieveListTable from './ArchieveListTable';
import PatientListTable from './PatientListTable';
import { useStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { Roles } from 'app/containers/Practitioner/PractitionerTable';
import { TemplatesTypeEnum } from 'app/containers/Templates/redux/types';
import { useIsPractitionerUser } from 'utils/hooks/isPractitionerUser';

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const PatientsListPage = () => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: PatientRepoSaga });
	useInjectReducer({ key: sliceKeyClinic, reducer: reducerClinic });
	useInjectSaga({ key: sliceKeyClinic, saga: ClinicRepoSaga });
	useInjectReducer({ key: sliceKeyPractitioner, reducer: reducerPractitioner });
	useInjectSaga({ key: sliceKeyPractitioner, saga: PractitionerRepoSaga });
	const showAlert = useSelector(selectShowAlert);
	const selectedClinicIsVirtual = useSelector(selectActiveClinicIsVirtual);
	const selectedPractitionerName = useSelector(selectSelectedPractitionerName);
	const selectedPractitionerId = useSelector(selectSelectedPractitioner);
	const useAddPatientPopupOpen = useSelector(selectAddPatientOpen);
	const selectArchivedSearchTermData = useSelector(selectArchivedSearchTerm);
	const selectSearchTermData = useSelector(selectSearchTerm);
	const isArchivePatientOpen = useSelector(selectArchivePatientOpen);
	const isPrePaymentOpen = useSelector(selectIsPrePaymentOpen);
	const isReassignPatientOpen = useSelector(selectReassignPatientOpen);
	const isUnArchivePatientOpen = useSelector(selectUnArchivePatientOpen);
	const isDeletePatientOpen = useSelector(selectDeletePatientOpen);
	const useAddPatient = useSelector(selectAddPatient);
	const usePatientsList = useSelector(selectPatientsList);
	const usePractitionerPayment = useSelector(selectPractitionerPayment);
	const usePractitionerRole = useSelector(selectPractitionerRole);
	const practitionersList: Practitioner[] = useSelector(
		selectPractitionersList,
	);
	const reAssignedPractitioner: string = useSelector(
		selectReAssignedPractitionerId,
	);
	const navigate = useNavigate();
	const form = useSelector(selectFrom);
	const selectedPatientInfo = useSelector(selectSelectedPatient);
	const snackBarStatus = useSelector(selectSnackBarStatus);
	const dispatch = useDispatch();
	const isPractitionerUser = useIsPractitionerUser();
	let practitionerId;
	if (!isPractitionerUser) {
		practitionerId = localStorage.getItem('practitionerId');
		const practionerName = localStorage.getItem('practitionerName');
		if (
			practitionerId &&
			practitionerId.length > 0 &&
			practionerName &&
			practionerName.length > 0
		) {
			dispatch(actionsPractitioner.setActivePractitioner(practitionerId));
			dispatch(actionsPractitioner.setActivePractitionerName(practionerName));
		} else {
			localStorage.clear();
		}
	} else {
		practitionerId = localStorage.getItem('practitionerId');
		const practionerName = localStorage.getItem('practitionerName');
		if (
			practitionerId &&
			practitionerId.length > 0 &&
			practionerName &&
			practionerName.length > 0
		) {
			dispatch(actionsPractitioner.setActivePractitioner(practitionerId));
			dispatch(actionsPractitioner.setActivePractitionerName(practionerName));
		}
	}

	const onSuccessStatusCallback = useCallback(() => {
		if (!selectedPatientInfo?.countryCode) {
			dispatch(actions.getCountryCodeByIp());
		}
		dispatch(actions.togglePrePaymentPopup({ visible: false, link: null }));
		dispatch(actions.toggleAddPatientPopup(true));
		dispatch(authActions.setLoading(false));
	}, [dispatch, selectedPatientInfo]);

	const onCloseAddPatient = () => {
		dispatch(actions.toggleAddPatientPopup(false));
		dispatch(authActions.setLoading(false));
	};
	const openPayment = link => {
		dispatch(authActions.setLoading(false));
		dispatch(actions.togglePrePaymentPopup({ visible: true, link: link }));
	};

	const styles = useStyles();
	const goToClinicTemplates = () => {
		const state = {
			templateType: TemplatesTypeEnum.CLINIC,
		};
		navigate('/templates', { state });
	};
	const goToPrivateTemplates = () => {
		const state = {
			templateType: TemplatesTypeEnum.PRIVATE,
		};
		navigate('/templates', { state });
	};
	const goToClinicAdmin = () => {
		navigate('/clinicAdmin');
	};
	const [value, setValue] = useState(0);
	const [chooseCountryCode, setChooseCountryCode] = useState('+1');

	const [editPopup, setEditPopup] = useState(false);

	const [showValidation, setshowValidation] = useState(false);
	// useEffect(() => {
	// 	if (
	// 		usePatientsList?.length &&
	// 		usePractitionerPayment?.details?.number_of_paid_customers >
	// 			usePatientsList?.length &&
	// 		usePractitionerPayment?.paymentMethod === 'Stripe'
	// 	) {
	// 		dispatch(actions.updateFormType({ type: 'Add' }));
	// 		dispatch(actions.setError(''));
	// 		onSuccessStatusCallback();
	// 	}
	// }, [
	// 	dispatch,
	// 	onSuccessStatusCallback,
	// 	usePatientsList?.length,
	// 	usePractitionerPayment?.details?.number_of_paid_customers,
	// 	usePractitionerPayment?.paymentMethod,
	// ]);
	const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = evt.target;
		dispatch(actions.updateFormValue({ key: name, value }));
	};
	const AddPatientPopuphandleOpen = () => {
		dispatch(authActions.setLoading(true));
		dispatch(actions.updateFormType({ type: 'Add' }));
		dispatch(actions.updateFormValue({ key: 'fullName', value: '' }));
		dispatch(actions.updateFormValue({ key: 'email', value: '' }));
		//  TODO: if we need this in future?
		// dispatch(actions.updateFormValue({ key: 'id', value: '' }));
		dispatch(actions.updateFormValue({ key: 'mobileNumber', value: '' }));
		dispatch(actions.updateFormValue({ key: 'countryCode', value: '' }));
		checkPaymentStatus();
	};
	const checkPaymentStatus = () => {
		dispatch(
			actions.checkPaymentStatus({
				onSuccessStatusCallback: onSuccessStatusCallback,
				openPayment: openPayment,
			}),
		);
	};
	const onPatientAddSuccess = () => {
		dispatch(actions.searchPatients(''));
	};
	const onPatientEditSuccess = () => {
		dispatch(actions.searchPatients(''));
	};
	const onPatientArchiveSuccess = () => {
		dispatch(actions.toggleArchivePatient());
	};
	const onPatientReassigneSuccess = () => {
		dispatch(actions.toggleReassignPatient());
		dispatch(actions.searchPatients(''));
		dispatch(actions.searchPatientsArchived(''));
	};
	const onPatientUnArchieveSuccess = () => {
		dispatch(actions.toggleUnArchivePatient());
	};
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const onArchiveSubmitForm = () => {
		try {
			dispatch(
				actions.updatePatientStatus({
					status: 1,
					callback: onPatientArchiveSuccess,
				}),
			);
		} catch (e) {
			console.log(e);
		}
	};
	const onReAssignSubmitForm = () => {
		try {
			dispatch(
				actions.reassignPractitioner({ callback: onPatientReassigneSuccess }),
			);
		} catch (e) {
			console.log(e);
		}
	};
	const onUnArchiveSubmitForm = () => {
		try {
			dispatch(
				actions.updatePatientStatus({
					status: 0,
					callback: onPatientUnArchieveSuccess,
				}),
			);
		} catch (e) {
			console.log(e);
		}
	};

	const onSubmitForm = () => {
		if (
			selectedPatientInfo.mobileNumber.length > 8 &&
			selectedPatientInfo.mobileNumber.length < 11
		) {
			try {
				if (selectedPatientInfo.type === 'Edit') {
					dispatch(actions.editPatient({ callback: onPatientEditSuccess }));
					return;
				}
				if (selectedPatientInfo.type === 'Add') {
					dispatch(actions.addPatient({ callback: onPatientAddSuccess }));
					return;
				}
			} catch (e) {
				console.log('Error in add/edit patient modal submit', e);
			}
		} else {
			dispatch(actions.setError('Invalid mobile number'));
		}
	};

	const handleSerach = () => {
		if (value === 0) {
			dispatch(actions.searchPatients(selectSearchTermData));
		} else {
			dispatch(actions.searchPatientsArchived(selectArchivedSearchTermData));
		}
	};

	useEffect(() => {
		dispatch(actions.searchPatients(''));
		dispatch(actions.setAddPatientError(''));
		dispatch(actions.searchPatientsArchived(''));
	}, [dispatch]);

	const closeArchivePatient = () => {
		dispatch(actions.toggleArchivePatient());
		dispatch(actions.setSelectedPatient({ id: '', fullName: '' }));
	};
	const closePrePaymentPatient = () => {
		dispatch(actions.togglePrePaymentPopup({ visible: false, link: null }));
	};
	const closeReassignPatient = () => {
		dispatch(actions.setSelectedPatient({ id: '', fullName: '' }));
		dispatch(actions.toggleReassignPatient());
	};
	const closeUnArchivePatient = () => {
		dispatch(actions.setSelectedPatient({ id: '', fullName: '' }));
		dispatch(actions.toggleUnArchivePatient());
	};

	const onDeleteSuccess = () => {
		dispatch(actions.setSelectedPatient({ id: '', fullName: '' }));
		dispatch(actions.toggleDeletePopupModal());
		dispatch(actions.searchPatientsArchived(''));
	};

	const onCloseError = () => {
		dispatch(actions.setAddPatientError(''));
	};

	const handleOnDeletePatient = () => {
		dispatch(
			actions.deleteSelectedPatient({
				callback: onDeleteSuccess,
			}),
		);
	};

	const closeDeletePatient = () => {
		dispatch(actions.setSelectedPatient({ id: '', fullName: '' }));
		dispatch(actions.toggleDeletePopupModal());
	};

	const handleClose = () => {
		dispatch(actions.setSnackBarStatus(false));
	};

	return (
		<Box display="flex" alignItems={'center'}>
			<Header />
			<Content>
				<Grid container spacing={2} className={clsx(styles.root)}>
					<Grid item lg={12} xs={12}>
						<Box display="flex" style={{ height: '100%' }}>
							<SideBar />
							<Container
								maxWidth={'xl'}
								sx={{ height: 'calc( 100vh - 80px )', overflowY: 'scroll' }}
							>
								{useAddPatient?.error && (
									<Alert
										severity="warning"
										onClose={() => {
											onCloseError();
										}}
									>
										{useAddPatient?.error}
									</Alert>
								)}

								{showAlert && (
									<Alert
										severity="warning"
										onClose={() => dispatch(actions.setShowAlert(false))}
									>
										<AlertTitle>Phone number not verified</AlertTitle>
										We couldn't send the client an SMS to download anydish,
										please make sure the number you saved is correct.
									</Alert>
								)}
								<Box className={clsx(styles.wrapper)}>
									<Box
										component="div"
										display="flex"
										justifyContent={'space-between'}
									>
										<Box component="div">
											<Typography
												className={clsx(styles.subheading)}
												variant="h1"
											>
												{selectedPractitionerName}
											</Typography>
										</Box>
										{isPractitionerUser && selectedClinicIsVirtual === 0 && (
											<Box className={clsx(styles.buttonBox)}>
												{/* {usePractitionerRole === Roles.CLINIC_ADMIN && (
													<Button
														onClick={goToClinicAdmin}
														className={clsx(styles.btn)}
														variant="contained"
													>
														Clinic admin
													</Button>
												)} */}
												{usePractitionerRole === Roles.CLINIC_ADMIN ||
													(usePractitionerRole === Roles.CLINIC_TEMPLATE && (
														<Button
															onClick={goToClinicTemplates}
															className={clsx(styles.btn)}
															variant="contained"
														>
															Clinic protocols
														</Button>
													))}
												<Button
													onClick={goToPrivateTemplates}
													className={clsx(styles.btn)}
													variant="contained"
												>
													My protocols
												</Button>
												<Button
													onClick={AddPatientPopuphandleOpen}
													className={clsx(styles.btn)}
													variant="contained"
												>
													Add a new client
												</Button>
												{usePatientsList?.length &&
												usePractitionerPayment.status !== 'APPROVED' &&
												usePractitionerPayment?.details
													?.number_of_paid_customers <=
													usePatientsList?.length ? (
													<p className={clsx(styles.explainText)}>
														* Payment for additional patients will be part of
														the next step
													</p>
												) : null}
											</Box>
										)}
									</Box>
									<Box component="div" className={clsx(styles.searchBox)}>
										<Container maxWidth={'xl'}>
											<Grid container>
												{/* <Grid item lg={6} xs={12}> */}
												<Box
													component="div"
													className={clsx(styles.tabBox)}
													display="flex"
													alignItems="center"
												>
													<Tabs
														value={value}
														onChange={handleChange}
														aria-label="basic tabs example"
													>
														<Tooltip title="Patients" placement="top">
															<Tab
																label={<img src={IconLine} alt="IconLine" />}
																{...a11yProps(0)}
																className={clsx(styles.BtnCircle)}
															/>
														</Tooltip>
														<Tooltip title="Archive patients" placement="top">
															<Tab
																label={<img src={IconBag} alt="IconBag" />}
																{...a11yProps(1)}
																className={clsx(styles.BtnCircle)}
															/>
														</Tooltip>
													</Tabs>
													<TableSearch
														placeholder={
															value == 0
																? 'Search client...'
																: 'Search archived client...'
														}
														type="name"
														endIcon={
															<IconButton
																type="submit"
																onClick={() => {
																	handleSerach();
																}}
																sx={{ p: '10px' }}
																aria-label="search"
															>
																<img src={IconSearch} alt={'IconSearch'} />
															</IconButton>
														}
														className={clsx(styles.SearchInput)}
														value={
															value == 0
																? selectSearchTermData
																: selectArchivedSearchTermData
														}
														onKeyUp={e => {
															if (e.key == 'Enter') {
																handleSerach();
															}
														}}
														onChanges={e => {
															if (value == 0) {
																dispatch(
																	actions.changesearchPatient(e.target.value),
																);
															} else {
																dispatch(
																	actions.changesearchArchivedPatient(
																		e.target.value,
																	),
																);
															}
														}}
													></TableSearch>
												</Box>
												{/* </Grid> */}
												{/* <Grid item lg={6} xs={12}></Grid> */}
											</Grid>
										</Container>
									</Box>
									<Box component="div" className={clsx(styles.TableBox)}>
										<TabPanel value={value} index={0}>
											<PatientListTable />
										</TabPanel>
										<TabPanel value={value} index={1}>
											<ArchieveListTable />
										</TabPanel>
									</Box>
								</Box>
							</Container>
							<UserRightBar />
						</Box>
					</Grid>
				</Grid>
			</Content>

			{/* Patient Popup */}
			<CustomModal
				btnTxt="Save"
				openModal={useAddPatientPopupOpen}
				setOpen={onCloseAddPatient}
				Icon={IconBluePlus}
				heading={`${selectedPatientInfo?.type} a new client`}
				secondHeading="Register a new client, make sure all the details are correct! "
				modalClass={[styles.patient]}
				onSubmit={onSubmitForm}
			>
				<Box component={`div`} className={styles.formGroup}>
					{form.error?.length > 0 && (
						<Alert severity="error">{form.error}</Alert>
					)}
					<Box>
						<InputLabel className={styles.label}>Client’s full name</InputLabel>
						<TextField
							className={styles.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							type="name"
							fullWidth
							placeholder=" Client’s full name "
							id="fullWidth"
							value={selectedPatientInfo.fullName}
							onChange={handleFieldChange}
							name="fullName"
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</Box>
					<Box>
						<InputLabel className={styles.label}>Client’s email</InputLabel>
						<TextField
							className={styles.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							fullWidth
							type="email"
							placeholder="Client’s email "
							id="fullWidth"
							value={selectedPatientInfo.email}
							onChange={handleFieldChange}
							name="email"
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</Box>
					{/* TODO: did we need this in future?
					<Box>
						<InputLabel className={styles.label}>
							Patient’s ID Number
						</InputLabel>
						<TextField
							className={styles.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							fullWidth
							type="text"
							placeholder="Patient’s ID Number "
							id="fullWidth"
							name="id"
							value={selectedPatientInfo.id}
							onChange={handleFieldChange}
						/>
					</Box> */}
					<Box>
						<InputLabel className={styles.label}>
							Client’s contact number
						</InputLabel>
						<Box component={`div`} className={styles.inputNumberBox}>
							<CountryCodeInput
								onChangeCountryCode={handleFieldChange}
								chooseCountryCode={chooseCountryCode}
								setChooseCountryCode={setChooseCountryCode}
							/>
							<TextField
								InputLabelProps={{ style: { color: '#645B70' } }}
								type="number"
								placeholder="Client’s contact number"
								id="fullWidth"
								value={selectedPatientInfo.mobileNumber}
								onChange={handleFieldChange}
								name="mobileNumber"
								onKeyDown={(e: any) => {
									if (e.key === 'Enter') {
										e.preventDefault();
									}
								}}
							/>
						</Box>
					</Box>
				</Box>
			</CustomModal>
			{/* ./Patient Popup */}
			{/* Edit Popup */}
			<CustomModal
				btnTxt="save"
				openModal={editPopup}
				setOpen={setEditPopup}
				Icon={IconEdit}
				heading="Edit"
				secondHeading="You are about to delete a client."
				modalClass={styles.EditPopup}
			>
				<InputLabel className={styles.label}>Client’s name</InputLabel>
				<TextField
					className={styles.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</CustomModal>
			{/* ./Edit Popup */}
			{/* Archive Popup */}
			<CustomModal
				btnTxt="Archive"
				openModal={isArchivePatientOpen}
				setOpen={closeArchivePatient}
				Icon={IconBlueArchive}
				heading="Archive"
				secondHeading="Archive the following client."
				modalClass={[styles.ReassignMClass, styles.archive]}
				onSubmit={onArchiveSubmitForm}
			>
				<InputLabel className={styles.label}>Client’s name</InputLabel>
				<TextField
					className={styles.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					value={selectedPatientInfo.fullName}
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</CustomModal>
			{/* ./Archive Popup */}
			{/* Reassign Popup */}
			<CustomModal
				btnTxt="Reassign"
				openModal={isReassignPatientOpen}
				setOpen={closeReassignPatient}
				Icon={IconBlueArchive}
				heading="Reassign"
				secondHeading="Reassign the following client."
				modalClass={[styles.ReassignMClass, styles.archive]}
				onSubmit={onReAssignSubmitForm}
				disabled={reAssignedPractitioner?.length > 0 ? false : true}
			>
				<InputLabel className={styles.label}>Client’s name</InputLabel>
				<TextField
					className={styles.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					value={selectedPatientInfo.fullName}
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
				<Box component={`div`} className={styles.formGroup}>
					<Box component="div" pb={3}>
						<Typography variant="h5">Choose a practitioner</Typography>
						<Typography variant="body1">
							Please reassign associated clients to another practitioner.
						</Typography>
					</Box>
					<SearchAutoComplete
						placeholder="Search archived practitioner..."
						items={practitionersList
							.filter(x => x._id !== selectedPractitionerId)
							.map(item => ({
								value: item._id,
								label: `${item.fullName} (${item.email})`,
							}))}
						handleOnChange={(e, val) => {
							dispatch(actions.setReAssignPractitionerId({ id: val.value }));
						}}
						endIcon={
							<IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
								<img src={IconSearch} alt={'IconSearch'} />
							</IconButton>
						}
						className={styles.searchInput}
					/>
				</Box>
			</CustomModal>
			{/* ./Reassign Popup */}
			{/* =========================================================================== */}
			{/* UnArchive Popup */}
			<CustomModal
				btnTxt="UnArchive"
				openModal={isUnArchivePatientOpen}
				setOpen={closeUnArchivePatient}
				Icon={IconBlueReassign}
				heading="UnArchive a Client"
				secondHeading="Reassign the following client to a different client."
				modalClass={[styles.ReassignMClass]}
				onSubmit={() => {
					onUnArchiveSubmitForm();
				}}
			>
				{showValidation && <Alert severity="error">Please enter values</Alert>}
				<InputLabel className={styles.label}>Client’s name</InputLabel>
				<TextField
					className={styles.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					value={selectedPatientInfo.fullName}
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</CustomModal>
			{/* ./UnArchive Popup */}
			{/* Delete Popup */}
			<CustomModal
				btnTxt="Delete"
				openModal={isDeletePatientOpen}
				onSubmit={handleOnDeletePatient}
				setOpen={closeDeletePatient}
				Icon={IconTrashRed}
				heading="Delete"
				secondHeading="You are about to delete a client."
				modalClass={[styles.TrashMClass]}
			>
				<InputLabel className={styles.label}>Client’s name</InputLabel>
				<TextField
					className={styles.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					value={selectedPatientInfo.fullName}
					disabled
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</CustomModal>
			{/* ./Delete Popup */}
			{/* PrePayment Popup */}
			<CustomModal
				btnTxt="Cancel"
				openModal={isPrePaymentOpen.visible}
				setOpen={closePrePaymentPatient}
				Icon={IconBluePlus}
				heading="Expand your practice: pay for your new client's access to anydish"
				secondHeading={''}
				modalClass={[styles.ReassignMClass, styles.archive]}
				onSubmit={closePrePaymentPatient}
				onlyOneButton={true}
			>
				<p className={styles.labelText}>Hi {selectedPractitionerName},</p>
				<p className={styles.labelText}>Welcome your new client to anydish!</p>
				<p className={styles.labelText}>
					{' '}
					To activate their access, complete your secure payment via{' '}
					{isPrePaymentOpen?.link?.includes('stripe')
						? 'Stripe'
						: 'PayPal or debit/credit card'}
					.{' '}
				</p>
				<p className={styles.labelText}>
					Click{' '}
					{isPrePaymentOpen?.link?.includes('stripe')
						? 'Pay for new client'
						: 'the proper button'}{' '}
					to proceed.
				</p>
				{isPrePaymentOpen?.link?.includes('stripe') && (
					<Box className={clsx(styles.btnBox)}>
						<Button
							onClick={() => {
								window.location.replace(isPrePaymentOpen.link);
							}}
							className={clsx(styles.btn)}
							variant="contained"
						>
							Pay for new client
						</Button>
					</Box>
				)}
				{isPrePaymentOpen?.link?.includes('paypal') && (
					<Box className={clsx(styles.btnBox)}>
						<PaypalProvider
							onSuccessStatusCallback={onSuccessStatusCallback}
							link={isPrePaymentOpen.link}
							sourceComponent={'addPatient'}
						/>
					</Box>
				)}
			</CustomModal>
			{/* ./PrePayment Popup */}
			<Box>
				<Stack spacing={2} sx={{ width: '100%' }}>
					<Snackbar
						open={snackBarStatus}
						autoHideDuration={2000}
						onClose={handleClose}
					>
						<Alert
							onClose={handleClose}
							severity="success"
							sx={{ width: '100%' }}
						>
							Client details updated successfully!
						</Alert>
					</Snackbar>
				</Stack>
			</Box>
		</Box>
	);
};

export default PatientsListPage;
