import { MenuItem, Select } from '@mui/material';
import {
	selectCountryCode,
	selectSelectedPatient,
} from 'app/containers/Patients/redux/selector';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { countries } from './types';
import { actions } from '../../containers/Patients/redux/slice';

const CountryCodeInput = ({
	onChangeCountryCode,
	chooseCountryCode,
	setChooseCountryCode,
}) => {
	const dispatch = useDispatch();
	const useCountryCode = useSelector(selectCountryCode);
	const selectedPatientInfo = useSelector(selectSelectedPatient);

	const setCountryCodeState = countryCode => {
		setChooseCountryCode(countryCode);
		dispatch(
			actions.updateFormValue({
				key: 'countryCode',
				value: countryCode,
			}),
		);
	};

	useEffect(() => {
		if (selectedPatientInfo?.countryCode) {
			setCountryCodeState(selectedPatientInfo.countryCode);
		} else if (useCountryCode?.countryCode) {
			setCountryCodeState(useCountryCode?.countryCode);
		}
	}, [selectedPatientInfo?.countryCode, useCountryCode?.countryCode]);

	const renderValue = select => {
		return select;
	};
	return (
		<>
			<Select
				id="country-code-select"
				value={chooseCountryCode}
				onChange={event => {
					onChangeCountryCode(event);
					setChooseCountryCode(event.target.value);
				}}
				MenuProps={{
					PaperProps: {
						style: {
							maxHeight: 300,
						},
					},
				}}
				name="countryCode"
				renderValue={renderValue}
			>
				{countries.map(countryCode => (
					<MenuItem key={countryCode.code} value={countryCode.countryCode}>
						{`${countryCode?.flag} ${countryCode?.name} (${countryCode?.countryCode})`}
					</MenuItem>
				))}
			</Select>
		</>
	);
};
export default CountryCodeInput;
