import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Container,
	Grid,
	InputAdornment,
} from '@mui/material';
import SmallHeading from 'app/components/Heading/SmallHeading';
import { TextField } from 'app/components/TextField';
import UserRightBar from 'app/components/UserRightBar';
import { SaveAsTemplate } from 'app/containers/Templates/SaveAsTemplate';
import { SelectTemplate } from 'app/containers/Templates/SelectTemplate';
import { TemplatesRepoSaga } from 'app/containers/Templates/redux/saga';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { DASHBOARD_TOP_BAR_HEIGHT, REACT_APP_MODE } from 'utils/constants';
import MainHeading from '../../../components/Heading/MainHeading';
import Content from '../../../components/content';
import {
	reducer as reducerTemplates,
	sliceKey as sliceKeyTemplates,
	actions as templatesActions,
} from '../../Templates/redux/slice';
import { PatientRepoSaga } from '../redux/saga';
import { selectSelectedPatient, selectSendMessage } from '../redux/selector';
import { actions, reducer, sliceKey } from '../redux/slice';
import { PatientDetail } from '../types';
import DataContainer, {
	SAVE_PROFILE_BUTTON_TEXT,
} from './components/Container';
import { useStyles } from './components/Exclude/styles';
import UserInfoAccordion from './components/UserInfo/UserInfoAccordion';
import {
	sendingDataToTemplateOrToUpdateUser,
	useUpdatingFarPerType,
	useUpdatingRequirementsPatient,
	useUpdatingRestrictions,
} from './utils';
import { MyDropzone } from 'app/components/Dropzone';
import { selectGetTemplateError } from 'app/containers/Templates/redux/selectors';
import { ResetTemplate } from 'app/containers/Templates/ResetTemplate';
import Header from 'app/components/Header';

const PatientDetails = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: PatientRepoSaga });
	useInjectReducer({ key: sliceKeyTemplates, reducer: reducerTemplates });
	useInjectSaga({ key: sliceKeyTemplates, saga: TemplatesRepoSaga });
	const useSendMessage = useSelector(selectSendMessage);
	const useGetTemplateError = useSelector(selectGetTemplateError);
	const patientDetail: PatientDetail = useSelector(selectSelectedPatient);
	const [clinicalSummary, setClinicalSummary] = useState('');
	const [macroNutrientsDetails, setMacroNutrientsDetails] = useState<any[]>([]);
	const GENDER = {
		0: 'Prefer not to say',
		1: 'Female',
		2: 'Male',
	};

	useEffect(() => {
		return () => {
			dispatch(actions.cleanPatient());
		};
	}, []);
	useEffect(() => {
		dispatch(actions.getPatient(location.pathname.split('/')[2]));
		dispatch(actions.fetchNutrientAndVitaminDetails(null));
		saveAsTemplateCallback();
	}, [dispatch, location.pathname]);
	const saveAsTemplateCallback = () => {
		dispatch(templatesActions.getAnydishTemplate());
		dispatch(templatesActions.getClinicTemplate());
		dispatch(templatesActions.getPrivateTemplate());
	};
	const {
		userVitamins,
		setUserVitamins,
		nutrients,
		setNutrients,
		vitaminMinerals,
		setVitaminMinerals,
		practitionerExcluded,
		setPractitionerExcluded,
		practitionerIncluded,
		setPractitionerIncluded,
		practitionerIngredientsWarning,
		setPractitionerIngredientsWarning,
	} = useUpdatingRequirementsPatient(setMacroNutrientsDetails, patientDetail);

	const { restrictions, setRestrictions } =
		useUpdatingRestrictions(patientDetail);

	useUpdatingFarPerType(macroNutrientsDetails, nutrients, setNutrients);

	const handleDescriptionChange = () => {
		const inputData = {
			_id: patientDetail['_id'],
			fullName: patientDetail.fullName,
			email: patientDetail.email,
			practitioner_id: patientDetail.practitioner_id,
			clinicalSummary,
		};
		dispatch(actions.putPatientClinicalSummery(inputData));
		if (clinicalSummary) {
			sendMessage(clinicalSummary, true);
		}
	};

	const handleUpdatePatientDetailChange = () => {
		const [userNutrients, userVitamins, updateDataForPatientDetail]: any =
			sendingDataToTemplateOrToUpdateUser(
				nutrients,
				vitaminMinerals,
				practitionerExcluded,
				practitionerIncluded,
				practitionerIngredientsWarning,
				restrictions,
				patientDetail,
			);
		dispatch(actions.putPatientDetails(updateDataForPatientDetail));
		let textMessage = '';
		if (clinicalSummary) {
			textMessage += `Message from your practitioner:\n ${clinicalSummary}\n`;
		}
		textMessage += '\nRequired nutrients:\n';
		let haveRequired = false;
		if (userNutrients?.length > 0 || userVitamins?.length > 0) {
			userNutrients.forEach(nut => {
				if (nut?.selectedValue?.toFilter) {
					const nutrient = nutrients.find(n => n.optionId === nut.optionId);
					if (nut?.selectedValue.key.includes('high')) {
						haveRequired = true;
						textMessage += `${nutrient?.optionText} - High\n`;
					}
					if (nut?.selectedValue.key.includes('low')) {
						haveRequired = true;
						textMessage += `${nutrient?.optionText} - Low\n`;
					}
				}
			});
			userVitamins.forEach(vit => {
				if (vit?.selectedValue?.toFilter) {
					const vitamin = vitaminMinerals.find(
						v => v.optionId === vit.optionId,
					);
					if (vit.selectedValue.key.includes('high')) {
						haveRequired = true;
						textMessage += `${vitamin?.optionText} - High\n`;
					}
					if (vit.selectedValue.key.includes('low')) {
						haveRequired = true;
						textMessage += `${vitamin?.optionText} - Low\n`;
					}
				}
			});
		}
		if (!haveRequired) {
			textMessage += 'You have no restrictions\n';
		}

		textMessage += '\nPreferred nutrients:\n';
		let havePreferred = false;
		if (userNutrients?.length > 0 || userVitamins?.length > 0) {
			userNutrients.forEach(nut => {
				if (!nut?.selectedValue?.toFilter) {
					const nutrient = nutrients.find(n => n.optionId === nut.optionId);
					if (nut?.selectedValue.key.includes('high')) {
						havePreferred = true;
						textMessage += `${nutrient?.optionText} - High\n`;
					}
					if (nut?.selectedValue.key.includes('low')) {
						havePreferred = true;
						textMessage += `${nutrient?.optionText} - Low\n`;
					}
				}
			});
			userVitamins.forEach(vit => {
				if (!vit?.selectedValue?.toFilter) {
					const vitamin = vitaminMinerals.find(
						v => v.optionId === vit.optionId,
					);
					if (vit.selectedValue.key.includes('high')) {
						havePreferred = true;
						textMessage += `${vitamin?.optionText} - High\n`;
					}
					if (vit.selectedValue.key.includes('low')) {
						havePreferred = true;
						textMessage += `${vitamin?.optionText} - Low\n`;
					}
				}
			});
		}
		if (!havePreferred) {
			textMessage += 'You have no restrictions\n';
		}
		const addToTextIngredientsMessage = message => {
			return message?.length ? message.join(', ') : 'You have no restrictions';
		};
		const ingredientsMessage = ingredients => {
			const message: any = [];
			ingredients?.forEach((ing: any) =>
				message.push(ing?.foodLabel ? ing.foodLabel : ''),
			);
			return addToTextIngredientsMessage(message);
		};

		textMessage += '\nExclude:\n';
		textMessage += `${ingredientsMessage(practitionerExcluded)}\n`;
		textMessage += '\nInclude:\n';
		textMessage += `${ingredientsMessage(practitionerIncluded)}\n`;

		textMessage += '\nWarning:\n';
		const practitionerRestrictions: any = [];
		restrictions.forEach((res: any) => {
			if (res.select) {
				practitionerRestrictions.push(res);
			}
		});
		const restrictionsMessage: any = [];
		if (practitionerRestrictions?.length > 0) {
			practitionerRestrictions.forEach(res =>
				restrictionsMessage.push(res?.optionText ? res.optionText : ''),
			);
			textMessage += `${restrictionsMessage.join(', ')}`;
		}
		if (practitionerIngredientsWarning?.length > 0) {
			const practitionerIngredientsWarningMessage: any = [];
			practitionerIngredientsWarning.forEach(incl =>
				practitionerIngredientsWarningMessage.push(
					incl?.foodLabel ? incl.foodLabel : '',
				),
			);
			textMessage += `${
				practitionerRestrictions?.length ? ', ' : ''
			}${practitionerIngredientsWarningMessage.join(', ')}\n`;
		}
		if (
			!practitionerRestrictions?.length &&
			!practitionerIngredientsWarning?.length
		) {
			textMessage += 'You have no restrictions\n';
		}

		sendMessage(textMessage, false);
		dispatch(actions.setPrescriptionSentStatus(true));
		dispatch(actions.setSnackBarStatus(true));
		dispatch(actions.sendMessage(''));
		navigate('/patients');
	};

	const sendMessage = (textMessage, showAlert) => {
		const prescriptionMessage = {
			receiver: patientDetail['_id'],
			message: textMessage,
			title: `From ${patientDetail.fullName} - ${
				new Date().toLocaleString().split(',')[0]
			}`,
		};
		dispatch(actions.sendMessageToPatient({ prescriptionMessage, showAlert }));
	};

	const handleSaveTemplate = (templateName, templateType) => {
		const [userNutrients, userVitamins, updateDataForPatientDetail] =
			sendingDataToTemplateOrToUpdateUser(
				nutrients,
				vitaminMinerals,
				practitionerExcluded,
				practitionerIncluded,
				practitionerIngredientsWarning,
				restrictions,
				patientDetail,
			);
		dispatch(
			templatesActions.saveAsTemplate({
				templateData: updateDataForPatientDetail,
				templateName,
				templateType,
				callback: saveAsTemplateCallback,
			}),
		);
	};
	const styles = useStyles();
	const handleCancelButton = () => {
		dispatch(actions.sendMessage(''));
		navigate('/patients');
	};
	return (
		<Box display="flex" alignItems={'center'}>
			<Header showWatchTraining={true} />
			<Content>
				<Container maxWidth={'xl'}>
					{useGetTemplateError && (
						<Alert
							severity="warning"
							onClose={() => dispatch(templatesActions.setGetTemplateError(''))}
						>
							<AlertTitle>{useGetTemplateError}</AlertTitle>
						</Alert>
					)}
					<Grid container spacing={2}>
						<Grid item xs={12} xl={12}>
							<Box className={clsx(styles.wrapper)}>
								<Box className={clsx(styles.textNameBox)}>
									<Box className={clsx(styles.nameAndTemplateBox)}>
										<Box className={clsx(styles.nameAndTemplateBox2)}>
											<MainHeading title={patientDetail.fullName} />
										</Box>
										<Box className={clsx(styles.resetTemplateView)}>
											<SelectTemplate patientDetail={patientDetail} />
										</Box>
									</Box>
									<Box
										style={{
											display: 'flex',
											flexDirection: 'row',
											width: '40%',
											justifyContent: 'flex-end',
										}}
									>
										{REACT_APP_MODE !== 'production' &&
											patientDetail?.fullName && <MyDropzone />}
										<Box
											style={{
												float: 'right',
												color: '#fff',
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<Button
												className={clsx(styles.button)}
												onClick={handleUpdatePatientDetailChange}
											>
												{SAVE_PROFILE_BUTTON_TEXT}
											</Button>
											<SaveAsTemplate handleSaveTemplate={handleSaveTemplate} />
											<ResetTemplate
												setNutrients={setNutrients}
												setVitaminMinerals={setVitaminMinerals}
												setPractitionerExcluded={setPractitionerExcluded}
												setPractitionerIncluded={setPractitionerIncluded}
												setPractitionerIngredientsWarning={
													setPractitionerIngredientsWarning
												}
												restrictions={restrictions}
												setRestrictions={setRestrictions}
											/>
										</Box>
									</Box>
								</Box>
								<Box display={'flex'}>
									<Grid item xs={6} md={6} xl={6}>
										<Box
											style={{
												width: '80%',
												marginTop: 0.3 * DASHBOARD_TOP_BAR_HEIGHT,
											}}
										>
											<small style={{ fontSize: 16, color: 'GrayText' }}>
												Message to client
											</small>
											<TextField
												fullWidth
												width={'80%'}
												value={
													patientDetail.clinicalSummary
														? patientDetail.clinicalSummary
														: clinicalSummary
												}
												rows={3}
												multiline
												onChange={event => {
													setClinicalSummary(event.target.value);
												}}
												name={'clinical_summary_input'}
												InputProps={{
													style: {
														fontSize: 15,
														lineHeight: '26px',
														fontWeight: '500',
														flexDirection: 'column',
														justifyContent: 'space-around',
														borderRadius: '20px',
														padding: '24px',
														width: '100%',
													},
													endAdornment: (
														<InputAdornment
															position="end"
															variant="filled"
															style={{ marginLeft: 'auto' }}
														></InputAdornment>
													),
												}}
												style={{
													borderRadius: '30px',
													background: '#FFF',
													margin: '5px 0px',
												}}
											/>
											<Box display="flex">
												<Button
													style={{
														backgroundColor: '#FFFFFF',
														color: '#3A1086',
													}}
													sx={{
														width: 100,
														height: 50,
														borderRadius: '50px',
														marginRight: 5,
													}}
													onClick={handleDescriptionChange}
												>
													Send
												</Button>
												{useSendMessage ? (
													<Alert
														severity="success"
														onClose={() => {
															dispatch(actions.sendMessage(''));
															setClinicalSummary('');
														}}
													>
														<AlertTitle>{useSendMessage}</AlertTitle>
													</Alert>
												) : null}
											</Box>
										</Box>
									</Grid>
									<Grid item xs={6} md={6} xl={6}>
										<Box
											style={{
												width: '100%',
												marginTop: 0.3 * DASHBOARD_TOP_BAR_HEIGHT,
											}}
										>
											<small style={{ fontSize: 16, color: 'GrayText' }}>
												Client Summary
											</small>
											<UserInfoAccordion title="Personal Info" marginSize={5}>
												<Box className={clsx(styles.userInfoBox)}>
													<Box
														className={clsx(styles.userInfoBoxItem)}
														style={{
															marginRight: '2%',
														}}
													>
														<SmallHeading
															subtitle="Gender"
															title={`${
																GENDER[patientDetail?.gender] ||
																'Did not specify'
															}`}
														/>
													</Box>
													<Box className={clsx(styles.userInfoBoxItem)}>
														<SmallHeading
															subtitle="Age"
															title={`${
																patientDetail?.age || 'Did not specify'
															}`}
														/>
													</Box>
													<Box
														className={clsx(styles.userInfoBoxItem)}
														style={{
															marginRight: '2%',
														}}
													>
														<SmallHeading
															subtitle="Height"
															title={`${
																patientDetail?.height || 'Did not specify'
															}`}
														/>
													</Box>
													<Box className={clsx(styles.userInfoBoxItem)}>
														<SmallHeading
															subtitle="Weight"
															title={`${
																patientDetail?.weight || 'Did not specify'
															}`}
														/>
													</Box>
												</Box>
											</UserInfoAccordion>
											<UserInfoAccordion title="Plan Summary" marginSize={10}>
												<Box className={clsx(styles.userInfoBox)}>
													<Box
														className={clsx(styles.userInfoBoxItem)}
														style={{
															marginRight: '2%',
														}}
													>
														<SmallHeading
															subtitle="Life style"
															title={`${
																patientDetail?.dieteryPreferences
																	?.life_style_array?.length
																	? patientDetail?.dieteryPreferences?.life_style_array?.map(
																			item => item.displayName,
																	  )
																	: 'Did not specify'
															}`}
														/>
													</Box>
													<Box className={clsx(styles.userInfoBoxItem)}>
														<SmallHeading
															subtitle="Restrictions"
															title={`${
																patientDetail?.dieteryPreferences?.restriction_array?.concat(
																	patientDetail?.practitionerPreferences
																		?.practitioner_ingredients_warning,
																)?.length
																	? patientDetail?.dieteryPreferences?.restriction_array
																			?.concat(
																				patientDetail?.practitionerPreferences
																					?.practitioner_ingredients_warning,
																			)
																			?.map(
																				item =>
																					item?.displayName || item?.foodLabel,
																			)
																	: 'Did not specify'
															}`}
														/>
													</Box>
													<Box
														className={clsx(styles.userInfoBoxItem)}
														style={{
															marginRight: '2%',
														}}
													>
														<SmallHeading
															subtitle="Required nutrients"
															title={`${
																patientDetail?.dieteryPreferences?.macro_array?.join(
																	', ',
																) || 'Did not specify'
															}`}
														/>
													</Box>
													<Box className={clsx(styles.userInfoBoxItem)}>
														<SmallHeading
															subtitle="Preferred nutrients"
															title={`${
																patientDetail?.dieteryPreferences?.micro_array?.join(
																	', ',
																) || 'Did not specify'
															}`}
														/>
													</Box>
													<Box
														className={clsx(styles.userInfoBoxItem)}
														style={{
															marginRight: '2%',
														}}
													>
														<SmallHeading
															subtitle="Exclude"
															title={`${
																patientDetail?.dieteryPreferences?.user_included
																	?.length
																	? patientDetail?.dieteryPreferences?.user_included?.map(
																			item => item.displayName,
																	  )
																	: 'Did not specify'
															}`}
														/>
													</Box>
													<Box className={clsx(styles.userInfoBoxItem)}>
														<SmallHeading
															subtitle="Include"
															title={`${
																patientDetail?.dieteryPreferences?.user_excluded
																	?.length
																	? patientDetail?.dieteryPreferences?.user_excluded?.map(
																			item => item.displayName,
																	  )
																	: 'Did not specify'
															}`}
														/>
													</Box>
												</Box>
											</UserInfoAccordion>
										</Box>
									</Grid>
								</Box>
								<DataContainer
									nutrients={nutrients}
									setNutrients={setNutrients}
									vitaminMinerals={vitaminMinerals}
									setVitaminMinerals={setVitaminMinerals}
									setPractitionerExcluded={setPractitionerExcluded}
									practitionerExcluded={practitionerExcluded}
									setPractitionerIncluded={setPractitionerIncluded}
									practitionerIncluded={practitionerIncluded}
									setPractitionerIngredientsWarning={
										setPractitionerIngredientsWarning
									}
									practitionerIngredientsWarning={
										practitionerIngredientsWarning
									}
									restrictions={restrictions}
									setRestrictions={setRestrictions}
									userVitamins={userVitamins}
									setUserVitamins={setUserVitamins}
									handleSave={handleUpdatePatientDetailChange}
									handleCancelButton={handleCancelButton}
									saveButtonText={SAVE_PROFILE_BUTTON_TEXT}
								/>
							</Box>
						</Grid>
					</Grid>
				</Container>
				<UserRightBar />
				{/* <ConflictsTemplatesPopup /> */}
			</Content>
		</Box>
	);
};

export default PatientDetails;
