import { delay, put, select, takeLatest, call } from 'redux-saga/effects';
import { selectFrom } from './selector';
import { actions } from './slice';
import { API_URL } from 'utils/constants';
import { request } from 'utils/request';
import { t } from 'i18next';
import { selectActiveClinic } from 'app/containers/Clinics/redux/selector';
import { emailValidator } from 'utils/helpers';
import queryString from 'query-string';
import { pageSize } from '../';

export function* getClinic(action) {
	const searchTerm = action.payload?.searchTerm || '';
	const page = action.payload?.page || 1;
	const clinicId = action.payload?.clinicId;
	const options = {
		method: 'GET',
	};
	const queries = queryString.stringify({
		searchTerm: searchTerm,
		pageSize: pageSize,
		pageNo: page,
		status: 0,
		clinic: clinicId,
	});
	try {
		const response = yield call(
			request,
			`${API_URL}/clinic/get-clinic/?${queries}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'BASIC_FOOD.FOOD_CATEGORIES_FETCH.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(actions.updatepractitionersList(response.data[0].results));
		if (response.data[0].results.length > 0) {
			yield put(actions.updatePageNo(response.data[0].meta.page));
			yield put(actions.updateTotalCount(response.data[0].meta.total));
		} else {
			yield put(actions.updatePageNo(0));
			yield put(actions.updateTotalCount(0));
		}
	} catch (e) {
		yield put(actions.setError('something went wrong'));
		console.log(e);
	}
}
export function* searchPractitionerRequest(action) {
	const searchTerm = action.payload?.searchTerm || '';
	const page = action.payload?.page || 1;

	let selected_clinic = yield select(selectActiveClinic);
	const options = {
		method: 'GET',
	};
	const queries = queryString.stringify({
		searchTerm: searchTerm,
		pageSize: pageSize,
		pageNo: page,
		status: 0,
		clinic: selected_clinic,
	});
	try {
		const response = yield call(
			request,
			`${API_URL}/practitioner/?${queries}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'BASIC_FOOD.FOOD_CATEGORIES_FETCH.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(actions.updatepractitionersList(response.data[0].results));
		if (response.data[0].results.length > 0) {
			yield put(actions.updatePageNo(response.data[0].meta.page));
			yield put(actions.updateTotalCount(response.data[0].meta.total));
		} else {
			yield put(actions.updatePageNo(0));
			yield put(actions.updateTotalCount(0));
		}
	} catch (e) {
		yield put(actions.setError('something went wrong'));
		console.log(e);
	}
}
export function* searchPractitionerArchivedRequest(action) {
	const searchTerm = action.payload?.searchTerm || '';
	const page = action.payload?.page || 1;
	yield delay(1000);
	let selected_clinic = yield select(selectActiveClinic);
	const options = {
		method: 'GET',
		// body: JSON.stringify({ searchTerm: searchTerm, pageSize: 100, pageNo: 1 }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/practitioner/?pageSize=${pageSize}&pageNo=${page}&status=1&clinic=${selected_clinic}${
				searchTerm.length > 0 ? '&searchTerm=' + searchTerm : ''
			}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'BASIC_FOOD.FOOD_CATEGORIES_FETCH.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(
			actions.updateArchivedpractitionersList(response.data[0].results),
		);
		if (response.data[0].results.length > 0) {
			yield put(actions.updatePageNoArchived(response.data[0].meta.page));
			yield put(actions.updateTotalCountArchived(response.data[0].meta.total));
		} else {
			yield put(actions.updatePageNoArchived(0));
			yield put(actions.updateTotalCountArchived(0));
		}
	} catch (e) {
		yield put(actions.setError('something went wrong'));
		console.log(e);
	}
}
export function* addPractitionerRequest(action) {
	yield delay(500);
	const { fullName, email, clinic_id, promoCode } = yield select(selectFrom);
	if (fullName.length === 0) {
		yield put(actions.setError('Please enter full name'));
		return;
	}
	if (email.length === 0) {
		yield put(actions.setError('Please enter email'));
		return;
	}
	if (!emailValidator(email)) {
		yield put(actions.setError('Please enter correct email'));
		return;
	}

	const options = {
		method: 'POST',
		body: JSON.stringify({ fullName, email, clinic_id, promoCode }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/practitioner/add`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.PRACTITIONER_ADD.SUCCESS'
		) {
			yield put(actions.setError(t(`${response.message}`)));
			return;
		}
		yield call(action?.payload?.callback);
		yield put(actions.addSuccess());
		yield put(actions.toggleAddPractitioner());
		yield put(actions.searchPractitioners({ searchTerm: '' }));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		yield put(actions.setError('something went wrong'));
		console.log(e);
	}
}
export function* getPractitionerRequest(action) {
	const id = action.payload;
	yield delay(1000);

	const options = {
		method: 'GET',
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/practitioner/${id}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.CONTACT_PERSON_GET.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(
			actions.updateFormValue({
				key: 'fullName',
				value: response.data.fullName,
			}),
		);
		yield put(
			actions.updateFormValue({ key: 'email', value: response.data.email }),
		);
		yield put(
			actions.updateFormValue({
				key: 'promoCode',
				value: response.data.promoCode,
			}),
		);
		yield put(
			actions.updateFormValue({ key: '_id', value: response.data._id }),
		);
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* editPractitionerRequest(action) {
	yield delay(500);
	const form: {
		fullName: string;
		email: string;
		clinic_id: string;
		_id: string;
		promoCode: string;
	} = yield select(selectFrom);

	if (form.fullName.length === 0) {
		yield put(actions.setError('Please enter full name'));
		return;
	}
	if (form.email.length === 0) {
		yield put(actions.setError('Please enter contact person email'));
		return;
	}
	if (!emailValidator(form.email)) {
		yield put(actions.setError('Please enter correct contact person email'));
		return;
	}
	const options = {
		method: 'PUT',
		body: JSON.stringify(form),
	};
	try {
		const response = yield call(request, `${API_URL}/practitioner`, options);
		if (
			response &&
			response.messageCode !== 'MESSAGE.PRACTITIONER_ADD.SUCCESS'
		) {
			yield put(actions.setError(t(`${response.message}`)));
			return;
		}
		yield call(action?.payload?.callback);
		yield put(actions.addSuccess());
		yield put(actions.toggleAddPractitioner());
		yield put(actions.searchPractitioners({ searchTerm: '' }));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}

export function* UpdatePractitionerStatusRequest(action) {
	yield delay(500);
	const { selectedId } = yield select(selectFrom);

	const options = {
		method: 'PUT',
		body: JSON.stringify({ _id: selectedId, status: action.payload.status }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/practitioner/status`,
			options,
		);
		if (response && response.messageCode !== 'MESSAGE.CLINIC_ADD.SUCCESS') {
			yield put(actions.setError(t(`${response.message}`)));
			return;
		}
		yield call(action?.payload?.callback);
		yield put(actions.addSuccess());
		if (action.payload.status == 0) {
			yield put(actions.toggleUnArchivePractitioner());
		} else {
			yield put(actions.toggleArchivePractitioner());
		}

		yield put(actions.searchPractitioners({ searchTerm: '' }));
		yield put(
			actions.searchPractitionersArchived({
				searchTerm: '',
			}),
		);
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* deletePractitionerRequest(action) {
	yield delay(1000);
	const { selectedId } = yield select(selectFrom);

	const options = {
		method: 'DELETE',
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/practitioner/${selectedId}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.CONTACT_PERSON_DELETE.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield call(action?.payload?.callback);
		yield put(
			actions.searchPractitionersArchived({
				searchTerm: '',
			}),
		);
		yield put(actions.toggleDeletePractitioner());
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* deletePractitioner(action) {
	yield delay(1000);
	const { id } = action.payload;

	const options = {
		method: 'DELETE',
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/practitioner/${id}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.CONTACT_PERSON_DELETE.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield call(action?.payload?.callback);
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* setApprovedPractitioner(action) {
	const { id, paymentStatus, numOfClients } = action.payload;

	const options = {
		method: 'POST',
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/practitioner/payment-status?id=${id}&paymentStatus=${paymentStatus}&numOfClients=${numOfClients}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.SET_APPROVED_PRACTITIONER.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield call(action?.payload?.callback);
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}

export function* setPractitionerRole(action) {
	yield delay(500);
	const { id, role } = action.payload;

	const options = {
		method: 'POST',
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/practitioner/set-role?id=${id}&role=${role}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.SET_ROLE_PRACTITIONER.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield call(action?.payload?.callback);
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}

export function* setConsumerStatus(action) {
	const { id, paymentStatus } = action.payload;

	const options = {
		method: 'POST',
		body: JSON.stringify({ id, paymentStatus }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/userprofile/subscription/update-premium-status/free-user`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.CONSUMER_STATUS_UPDATE.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield call(action?.payload?.callback);
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* searchPractitionerClinicRequest(action) {
	yield delay(1000);
	const options = {
		method: 'GET',
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/clinic/withpractitioner`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'BASIC_FOOD.FOOD_CATEGORIES_FETCH.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(actions.updatepractitionersclinicList(response.data[0].results));
		if (response.data[0].results.length > 0) {
			yield put(actions.updatePageNo(response.data[0].meta.page));
		} else {
			yield put(actions.updatePageNo(0));
			yield put(actions.updateTotalCount(0));
		}
	} catch (e) {
		yield put(actions.setError('something went wrong'));
		console.log(e);
	}
}
export function* PractitionerRepoSaga() {
	yield takeLatest(
		actions.searchPractitionersArchived.type,
		searchPractitionerArchivedRequest,
	);
	yield takeLatest(actions.searchPractitioners.type, searchPractitionerRequest);
	yield takeLatest(
		actions.changesearchPractitioners.type,
		searchPractitionerRequest,
	);
	yield takeLatest(
		actions.changesearchArchivedPractitioners.type,
		searchPractitionerArchivedRequest,
	);
	yield takeLatest(actions.addPractitioner.type, addPractitionerRequest);
	yield takeLatest(actions.editPractitioner.type, editPractitionerRequest);
	yield takeLatest(actions.fetchEditPractitioner.type, getPractitionerRequest);
	yield takeLatest(
		actions.updatePractitionerStatus.type,
		UpdatePractitionerStatusRequest,
	);
	yield takeLatest(actions.deletePractitioner.type, deletePractitionerRequest);
	yield takeLatest(actions.deleteSelectedPractitioner.type, deletePractitioner);
	yield takeLatest(
		actions.setApprovedPractitioner.type,
		setApprovedPractitioner,
	);
	yield takeLatest(actions.setPractitionerRole.type, setPractitionerRole);
	yield takeLatest(actions.setConsumerStatus.type, setConsumerStatus);
	yield takeLatest(
		actions.searchPractitionersClinic.type,
		searchPractitionerClinicRequest,
	);
	yield takeLatest(actions.getClinic.type, getClinic);
}
